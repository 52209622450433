// COMPONENTS
import React from 'react'
import Page from 'templates/Page'
// CONTENT
import content from 'content/about.yml'
import layout from 'content/layout.yml'

// About page
const AboutPage = () => {
  return <Page content={{ ...layout, ...content }} />
}

export default AboutPage
